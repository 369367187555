
import { React, useEffect, useState, useRef } from 'react';
import '../font/ModernH-Bold.ttf';
import '../font/ModernH-Light.ttf';
import '../font/ModernH-Medium.ttf';
import dayjs from 'dayjs';
import axios from 'axios';
import OverviewSvg from '../component/SVG/OverviewSvg';
import Navbar from '../component/Nav';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { useNavigate } from 'react-router-dom';

function Overview() {
  const { instance, accounts } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const navigate = useNavigate();

  useEffect(() => {
    if (!activeAccount) {
      navigate("/login");
    }
  }, [activeAccount]);

  let procedureName = ["up_DisplayProductStatusByLine", "up_DisplayMainTrakingByLine_fc", "up_DisplayMainTrakingByLine_rc",
    "up_Display_Stock", "up_DisplayShipmentPDP_MSG", "up_DisplayOMSG", "up_DisplayProductStatusByBush", "up_seq_elapsetime", "up_Display_Part"]

  let time = null;
  const imgRef = useRef();
  const [fetchData, setData] = useState("Not updated");
  const [fAxleData, setFaxleData] = useState([])
  const [rAxleData, setRaxleData] = useState([])
  const [alarmMsg, setAlarmMsg] = useState("");
  const [height, setHeight] = useState(0)
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    window.addEventListener("load", setHeight(window.innerHeight))
    const debouncedHandleResize = debounce(function handleResize() {
      setHeight(window.innerHeight)
    }, 500)

    let interval2 = setInterval(() => {
      alarmUpdate("AlarmText", "AlarmBox");
    }, 2000)

    getOverviewData(true);
    let interval = setInterval(() => {
      getOverviewData(false);
    }, 5000)



    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
      clearInterval(interval);
      clearInterval(interval2);
    }

  }, [fetchData.time])

  const changeText = () => {
    try {
      time = dayjs(fetchData.time).format('MM.DD.YYYY HH:mm')

      fAxleData.forEach((v) => {
        fAxleData[v.station_code] = v
      });

      rAxleData.forEach((v) => {
        rAxleData[v.station_code] = v
      });

      // --------------------------------------------- Update at updated time
      imgRef.current.getElementById("currTime").children[0].textContent = time

      // --------------------------------------------- Update at Middle Table
      if (fetchData.data && fetchData.data[procedureName[0]]) {
        updateText("frontOrder1", procedureName[0], "id", "FCOrder")
        updateText("frontWork1", procedureName[0], "id", "FCStock")
        updateText("frontResult1", procedureName[0], "id", "FCResult")
        updateText("frontGap1", procedureName[0], "id", "FCGAP")
        updateText("rearOrder1", procedureName[0], "id", "RCOrder")
        updateText("rearWork1", procedureName[0], "id", "RCStock")
        updateText("rearResult1", procedureName[0], "id", "RCResult")
        updateText("rearGap1", procedureName[0], "id", "RCGAP")
      }

      // --------------------------------------------- Update at front Axle, front sub
      if (fAxleData?.length > 0) {
        LaneUpdate("fa01", "M200", "id", 'f')
        LaneUpdate("faId01", "M200", "color", 'f')
        LaneUpdate("fa02", "M202", "id", 'f')
        LaneUpdate("faId02", "M202", "color", 'f')
        LaneUpdate("fa03", "M203", "id", 'f')
        LaneUpdate("faId03", "M203", "color", 'f')
        LaneUpdate("fa04", "M204", "id", 'f')
        LaneUpdate("faId04", "M204", "color", 'f')
        LaneUpdate("fa05", "M205", "id", 'f')
        LaneUpdate("faId05", "M205", "color", 'f')
        LaneUpdate("fa06", "M206", "id", 'f')
        LaneUpdate("faId06", "M206", "color", 'f')
        LaneUpdate("fa07", "M207", "id", 'f')
        LaneUpdate("faId07", "M207", "color", 'f')
        LaneUpdate("fa08", "M210", "id", 'f')
        LaneUpdate("faId08", "M210", "color", 'f')
        LaneUpdate("fa09", "M220", "id", 'f')
        LaneUpdate("faId09", "M220", "color", 'f')
        LaneUpdate("fa10", "M230", "id", 'f')
        LaneUpdate("faId10", "M230", "color", 'f')
        LaneUpdate("fa11", "M240", "id", 'f')
        LaneUpdate("faId11", "M240", "color", 'f')
        LaneUpdate("fa12", "M250", "id", 'f')
        LaneUpdate("faId12", "M250", "color", 'f')
        LaneUpdate("fa13", "M260", "id", 'f')
        LaneUpdate("faId13", "M260", "color", 'f')
        LaneUpdate("fa14", "M270", "id", 'f')
        LaneUpdate("faId14", "M270", "color", 'f')
        LaneUpdate("fa15", "M280", "id", 'f')
        LaneUpdate("faId15", "M280", "color", 'f')
        LaneUpdate("fa16", "M290", "id", 'f')
        LaneUpdate("faId16", "M290", "color", 'f')
        LaneUpdate("fa17", "M300", "id", 'f')
        LaneUpdate("faId17", "M300", "color", 'f')
        LaneUpdate("fa18", "M310", "id", 'f')
        LaneUpdate("faId18", "M310", "color", 'f')
        LaneUpdate("fa19", "M320", "id", 'f')
        LaneUpdate("faId19", "M320", "color", 'f')
        // LaneUpdate("fs01", "S010", "id", 'f')
        // LaneUpdate("fsId01", "S010", "color", 'f')
        // LaneUpdate("fs04", "S040", "id", 'f')
        // LaneUpdate("fsId04", "S040", "color", 'f')
        // LaneUpdate("fs05", "S050", "id", 'f')
        // LaneUpdate("fsId05", "S050", "color", 'f')
        // LaneUpdate("fs09", "S090", "id", 'f')
        // LaneUpdate("fsId09", "S090", "color", 'f')
        LaneUpdate("faEx4", "M201", "id", 'f')
        LaneUpdate("faExId4", "M201", "color", 'f')
        LaneUpdate("faEx1", "M208", "id", 'f')
        LaneUpdate("faExId1", "M208", "color", 'f')
        LaneUpdate("faEx2", "M209", "id", 'f')
        LaneUpdate("faExId2", "M209", "color", 'f')
        LaneUpdate("faEx3", "M313", "id", 'f')
        LaneUpdate("faExId3", "M313", "color", 'f')
      }
      // --------------------------------------------- Update at rear axle
      if (rAxleData?.length > 0) {
        LaneUpdate("ra01", "M001", "id", 'r')
        LaneUpdate("raId01", "M001", "color", 'r')
        LaneUpdate("ra02", "M002", "id", 'r')
        LaneUpdate("raId02", "M002", "color", 'r')
        LaneUpdate("ra03", "M010", "id", 'r')
        LaneUpdate("raId03", "M010", "color", 'r')
        LaneUpdate("ra04", "M020", "id", 'r')
        LaneUpdate("raId04", "M020", "color", 'r')
        LaneUpdate("ra05", "M030", "id", 'r')
        LaneUpdate("raId05", "M030", "color", 'r')
        LaneUpdate("ra06", "M040", "id", 'r')
        LaneUpdate("raId06", "M040", "color", 'r')
        LaneUpdate("ra07", "M050", "id", 'r')
        LaneUpdate("raId07", "M050", "color", 'r')
        LaneUpdate("ra08", "M060", "id", 'r')
        LaneUpdate("raId08", "M060", "color", 'r')
        LaneUpdate("ra09", "M070", "id", 'r')
        LaneUpdate("raId09", "M070", "color", 'r')
        LaneUpdate("ra10", "M080", "id", 'r')
        LaneUpdate("raId10", "M080", "color", 'r')
        LaneUpdate("ra11", "M090", "id", 'r')
        LaneUpdate("raId11", "M090", "color", 'r')
        LaneUpdate("ra12", "M100", "id", 'r')
        LaneUpdate("raId12", "M100", "color", 'r')
        LaneUpdate("ra13", "M110", "id", 'r')
        LaneUpdate("raId13", "M110", "color", 'r')
        LaneUpdate("ra14", "M120", "id", 'r')
        LaneUpdate("raId14", "M120", "color", 'r')
        LaneUpdate("ra15", "M130", "id", 'r')
        LaneUpdate("raId15", "M130", "color", 'r')
        LaneUpdate("ra16", "M140", "id", 'r')
        LaneUpdate("raId16", "M140", "color", 'r')
        LaneUpdate("ra18", "M161", "id", 'r')
        LaneUpdate("raId18", "M161", "color", 'r')
        LaneUpdate("ra19", "M162", "id", 'r')
        LaneUpdate("raId19", "M162", "color", 'r')
        LaneUpdate("ra20", "M170", "id", 'r')
        LaneUpdate("raId20", "M170", "color", 'r')

        LaneUpdate("ra21", "M180", "id", 'r')
        LaneUpdate("raId21", "M180", "color", 'r')

        LaneUpdate("raEx1", "M005", "id", 'r')
        LaneUpdate("raExId1", "M005", "color", 'r')
        LaneUpdate("raEx2", "M075", "id", 'r')
        LaneUpdate("raExId2", "M075", "color", 'r')
        LaneUpdate("raEx3", "M076", "id", 'r')
        LaneUpdate("raExId3", "M076", "color", 'r')
        LaneUpdate("raEx4_1", "M150", "id", 'r')
        LaneUpdate("raExId4_1", "M150", "color", 'r')
        LaneUpdate("raEx4_2", "M151", "id", 'r')
        LaneUpdate("raExId4_2", "M151", "color", 'r')
        LaneUpdate("raEx4_3", "M152", "id", 'r')
        LaneUpdate("raExId4_3", "M152", "color", 'r')
        LaneUpdate("raEx5_1", "M163", "id", 'r')
        LaneUpdate("raExId5_1", "M163", "color", 'r')
        LaneUpdate("raEx5_2", "M164", "id", 'r')
        LaneUpdate("raExId5_2", "M164", "color", 'r')
        LaneUpdate("raEx6_1", "M171", "id", 'r')
        LaneUpdate("raExId6_1", "M171", "color", 'r')
        LaneUpdate("raEx6_2", "M172", "id", 'r')
        LaneUpdate("raExId6_2", "M172", "color", 'r')
        LaneUpdate("raEx6_3", "M173", "id", 'r')
        LaneUpdate("raExId6_3", "M173", "color", 'r')
      }

      // --------------------------------------------- Update at front table, rear table
      if (fetchData.data && fetchData.data[procedureName[3]]) {
        updateText("frontAxleTableVar", procedureName[3], "id", "FC_TotalStock")
        updateText("rearAxleTableVar", procedureName[3], "id", "RC_TotalStock")
        updateText("frontMUAvar", procedureName[3], "id", "FC_Line_Stock")
        updateText("frontMBUSIvar", procedureName[3], "id", "FC_Mbusi_Stock")
        updateText("frontRMBUSIvar", procedureName[3], "id", "FC_Transport_Stock")
        updateText("frontMUA_Id", procedureName[3], "id", "FC_Line_StockCell")
        updateText("frontMBUSI_Id", procedureName[3], "id", "FC_Mbusi_StockCell")
        updateText("frontRMBUSI_Id", procedureName[3], "id", "RC_Transport_StockCell")
        updateText("rearMUAvar", procedureName[3], "id", "RC_Line_Stock")
        updateText("rearMBUSIvar", procedureName[3], "id", "RC_Mbusi_Stock")
        updateText("rearRMBUSIvar", procedureName[3], "id", "RC_Transport_Stock")
        updateText("rearMUA_Id", procedureName[3], "id", "RC_Line_StockCell")
        updateText("rearMBUSI_Id", procedureName[3], "id", "RC_Mbusi_StockCell")
        updateText("rearRMBUSI_Id", procedureName[3], "id", "RC_Transport_StockCell")
      }

      if (fetchData.data && fetchData.data[procedureName[4]]) {
        // --------------------------------------------- Update at storage & shipment
        updateStockText("frontStockVar", procedureName[4], "id", "FC_STOCK_SHIP")
        updateStockText("rearStockVar", procedureName[4], "id", "RC_STOCK_SHIP")

        // --------------------------------------------- Update at Dock A, Dock B
        updateStockText("dockAvar", procedureName[4], "id", "DOCK_A_CNT")
        updateStockText("dockA1", procedureName[4], "id", "DOCK_A_CELL3")
        updateStockText("dockA2", procedureName[4], "id", "DOCK_A_CELL2")
        updateStockText("dockA3", procedureName[4], "id", "DOCK_A_CELL1")
        updateStockText("dockBvar", procedureName[4], "id", "DOCK_B_CNT")
        updateStockText("dockB1", procedureName[4], "id", "DOCK_B_CELL3")
        updateStockText("dockB2", procedureName[4], "id", "DOCK_B_CELL2")
        updateStockText("dockB3", procedureName[4], "id", "DOCK_B_CELL1")
      }

      if (fetchData.data && fetchData.data[procedureName[6]]) {
        // --------------------------------------------- Update at Rear Sub Carrier
        updateText("wCarrierPvar", procedureName[6], "id", "WH_PLAN")
        updateText("wCarrierRvar", procedureName[6], "id", "WH_SHIFT")
        updateText("wCarrierSLvar", procedureName[6], "id", "WL_STOCK")
        updateText("wCarrierSRvar", procedureName[6], "id", "WR_STOCK")
        updateText("aCarrierPvar", procedureName[6], "id", "AX_PLAN")
        updateText("aCarrierRvar", procedureName[6], "id", "AX_SHIFT")
        updateText("aCarrierSvar", procedureName[6], "id", "AX_STOCK")
      }

      if (fetchData.data && fetchData.data[procedureName[3]]) {
        // --------------------------------------------- Update at Front and Rear table MBUSI stock color
        updateText("MBUSI_REC_F_1", procedureName[3], "color", "FC_Mbusi_Stock")
        updateText("MBUSI_REC_F_2", procedureName[3], "color", "FC_Mbusi_Stock")
        updateText("MBUSI_REC_F_3", procedureName[3], "color", "FC_Mbusi_Stock")
        updateText("MBUSI_REC_R_1", procedureName[3], "color", "RC_Mbusi_Stock")
        updateText("MBUSI_REC_R_2", procedureName[3], "color", "RC_Mbusi_Stock")
        updateText("MBUSI_REC_R_3", procedureName[3], "color", "RC_Mbusi_Stock")
        updateTextColor("f", procedureName[3], "FC_Mbusi_Stock")
        updateTextColor("r", procedureName[3], "RC_Mbusi_Stock")
      }

      if (fetchData.data && fetchData.data[procedureName[7]]) {
        // --------------------------------------------- Update at Elapsed time
        updateElapse("elapseTime", procedureName[7], 'ELAPSED_SEQ')
      }

      if (fetchData.data && fetchData.data[procedureName[4]]) {
        // --------------------------------------------- Update at Storage & Shipment
        updateStorageColor();
      }

      if (fetchData.data && fetchData.data[procedureName[8]]) {
        // --------------------------------------------- Update at FR SUB
        updateText("fs05", procedureName[8], "id", "SGEAR"); // S/Gear ▶ 02
        updateText("fs01", procedureName[8], "id", "TBAR"); // T/Bar
      }

      if (fAxleData?.length > 0) {
        // --------------------------------------------- Update at front axle alarm
        updateAxleAlarm("faidAlrm01", "M200", "Alarm", "f")
        updateAxleAlarm("faidAlrm02", "M202", "Alarm", "f")
        updateAxleAlarm("faidAlrm03", "M203", "Alarm", "f")
        updateAxleAlarm("faidAlrm04", "M204", "Alarm", "f")
        updateAxleAlarm("faidAlrm05", "M205", "Alarm", "f")
        updateAxleAlarm("faidAlrm06", "M206", "Alarm", "f")
        updateAxleAlarm("faidAlrm07", "M207", "Alarm", "f")
        updateAxleAlarm("faidAlrm08", "M210", "Alarm", "f")
        updateAxleAlarm("faidAlrm09", "M220", "Alarm", "f")
        updateAxleAlarm("faidAlrm10", "M230", "Alarm", "f")
        updateAxleAlarm("faidAlrm11", "M240", "Alarm", "f")
        updateAxleAlarm("faidAlrm12", "M250", "Alarm", "f")
        updateAxleAlarm("faidAlrm13", "M260", "Alarm", "f")
        updateAxleAlarm("faidAlrm14", "M270", "Alarm", "f")
        updateAxleAlarm("faidAlrm15", "M280", "Alarm", "f")
        updateAxleAlarm("faidAlrm16", "M290", "Alarm", "f")
        updateAxleAlarm("faidAlrm17", "M300", "Alarm", "f")
        updateAxleAlarm("faidAlrm18", "M310", "Alarm", "f")
        updateAxleAlarm("faidAlrm19", "M320", "Alarm", "f")
        // updateAxleAlarm("fsidAlrm01", "S010", "Alarm", "f")
        // updateAxleAlarm("fsidAlrm04", "S040", "Alarm", "f")
        // updateAxleAlarm("fsidAlrm05", "S050", "Alarm", "f")
        // updateAxleAlarm("fsidAlrm09", "S090", "Alarm", "f")
      }

      if (rAxleData?.length > 0) {
        // --------------------------------------------- Update at rear axle alarm
        updateAxleAlarm("raidAlrm01", "M001", "Alarm", "r")
        updateAxleAlarm("raidAlrm02", "M002", "Alarm", "r")
        updateAxleAlarm("raidAlrm03", "M010", "Alarm", "r")
        updateAxleAlarm("raidAlrm04", "M020", "Alarm", "r")
        updateAxleAlarm("raidAlrm05", "M030", "Alarm", "r")
        updateAxleAlarm("raidAlrm06", "M040", "Alarm", "r")
        updateAxleAlarm("raidAlrm07", "M050", "Alarm", "r")
        updateAxleAlarm("raidAlrm08", "M060", "Alarm", "r")
        updateAxleAlarm("raidAlrm09", "M070", "Alarm", "r")
        updateAxleAlarm("raidAlrm10", "M080", "Alarm", "r")
        updateAxleAlarm("raidAlrm11", "M090", "Alarm", "r")
        updateAxleAlarm("raidAlrm12", "M100", "Alarm", "r")
        updateAxleAlarm("raidAlrm13", "M110", "Alarm", "r")
        updateAxleAlarm("raidAlrm14", "M120", "Alarm", "r")
        updateAxleAlarm("raidAlrm15", "M130", "Alarm", "r")
        updateAxleAlarm("raidAlrm16", "M140", "Alarm", "r")
        updateAxleAlarm("raidAlrm18", "M161", "Alarm", "r")
        updateAxleAlarm("raidAlrm19", "M162", "Alarm", "r")
        updateAxleAlarm("raidAlrm20", "M170", "Alarm", "r")
        updateAxleAlarm("raidAlrm21", "M180", "Alarm", "r")
      }
      // console.log("Data successfully updated")
    } catch (err) {
      console.log("[ERROR][changeText]", err);
    }
  }

  // handle screen resize 
  const debounce = (fn, ms) => {
    let timer
    return () => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

  // API call for Data 
  const getOverviewData = (isInitialize) => {

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then(response => {
      const accessToken = response.accessToken;

      axios.get('/api/data/overview/', { headers: { 'version': '1.0.3', 'Authorization': `Bearer ${accessToken}` }, withCredentials: true })
        .then((response) => {
          setData(response.data)
          setAlarmMsg(response.data.data[procedureName[5]])
          setFaxleData(response.data.data[procedureName[1]])
          setRaxleData(response.data.data[procedureName[2]])
          changeText();

          if (!isInitialize) {
            alarmUpdate("AlarmText", "AlarmBox");
          }
        }).catch((err) => {
          if (err?.response?.status === 401) {
            alert("Unauthorized request. Please log in again.");
            const logoutRequest = {
              idTokenHint: accessToken,
              account: instance.getActiveAccount(),
              postLogoutRedirectUri: "/",
              mainWindowRedirectUri: "/",
            };
            instance.logoutRedirect(logoutRequest).catch((e) => {
              console.error(e);
            });
          } else if (err?.response?.status === 403) {
            const resolveWithSomeData = new Promise(resolve => setTimeout(() => {
              resolve();
            }, 3000));

            resolveWithSomeData.then(() => {
              window.customRegistration.unregister().then(() => {
                window.location.reload();
              });
            });
          }
        });
    }).catch(error => {
      console.log("[ERROR] acquireTokenSilent, ", error);
      instance.acquireTokenRedirect(request);
    });
  }

  // update for alarmUpdate
  const alarmUpdate = (msg, box) => {
    try {
      if (Object.is(alarmMsg[0]["OV_MSG"], null)) {
        imgRef.current.getElementById(msg).children[0].textContent = "DNA(Develop Navigate Achieve)";
        imgRef.current.getElementById(box).setAttribute("fill", "gray");
        imgRef.current.getElementById(msg).children[0].setAttribute("font-size", "50")
        setCounter(counter - counter);
      }
      else {
        if (alarmMsg[0]["OV_MSG"].search(",") == -1) {
          imgRef.current.getElementById(msg).children[0].textContent = alarmMsg[0]["OV_MSG"];
          imgRef.current.getElementById(box).setAttribute("fill", "red");
          setCounter(counter - counter);
        }
        else if (alarmMsg[0]["OV_MSG"].search(",") >= 1) {
          imgRef.current.getElementById(msg).children[0].textContent = alarmMsg[0]["OV_MSG"].split(',')[counter];
          imgRef.current.getElementById(box).setAttribute("fill", "red");
          setCounter(counter + 1);
          if (alarmMsg[0]["OV_MSG"].split(',')[counter + 1] === undefined) {
            setCounter(counter - counter);
          }
        }
        else {
          imgRef.current.getElementById(msg).children[0].textContent = "DNA(Develop Navigate Achieve)";
          imgRef.current.getElementById(box).setAttribute("fill", "gray");
          imgRef.current.getElementById(msg).children[0].setAttribute("font-size", "50")
          setCounter(counter - counter);
        }
        if (imgRef.current.getElementById(msg).children[0].textContent.length > 28) {
          var length = (imgRef.current.getElementById(msg).children[0].textContent.length - 28) / 2;
          imgRef.current.getElementById(msg).children[0].setAttribute("font-size", (50 - length).toString())
        }
      }
    } catch (err) {
      console.log("[ERROR][alarmUpdate] Param : ", msg, box);
      console.log("[ERROR][alarmUpdate]", err);
    }
  }


  const LaneUpdate = (point, station, type, axle) => {
    try {
      if (type === 'id') {
        if (axle === 'f') {
          imgRef.current.getElementById(point).children[0].textContent = fAxleData[station]["SEQ"]
        }
        else if (axle === 'r') {
          imgRef.current.getElementById(point).children[0].textContent = rAxleData[station]["SEQ"]
        }
      }
      else if (type === 'color') {
        if (axle === 'f') {
          imgRef.current.getElementById(point).setAttribute("fill", "#" + fAxleData[station]["CarTypeColor"].substr(3, 8))
        }
        else if (axle === 'r') {
          imgRef.current.getElementById(point).setAttribute("fill", "#" + rAxleData[station]["CarTypeColor"].substr(3, 8))
        }
      }
    } catch (err) {
      console.log("[ERROR][LaneUpdate] Param : ", point, station, type, axle);
      console.log("[ERROR][LaneUpdate]", err);
    }
  }

  //update axle lane alarm
  const updateAxleAlarm = (point, station, dataId, axle) => {
    try {
      if (axle === 'f') {
        if (fAxleData[station]["Alarm"] == 0) {
          imgRef.current.getElementById(point).setAttribute("fill", "white");

        }
        else if (fAxleData[station]["Alarm"] == 1) {
          imgRef.current.getElementById(point).setAttribute("fill", "red");

        }
        else if (fAxleData[station]["Alarm"] == 2) {
          imgRef.current.getElementById(point).setAttribute("fill", "orange");
        }
      }

      else if (axle === 'r') {
        if (rAxleData[station]["Alarm"] == 0) {
          imgRef.current.getElementById(point).setAttribute("fill", "white");

        }
        else if (rAxleData[station]["Alarm"] == 1) {
          imgRef.current.getElementById(point).setAttribute("fill", "red");

        }
        else if (rAxleData[station]["Alarm"] == 2) {
          imgRef.current.getElementById(point).setAttribute("fill", "orange");
        }
      }
    } catch (err) {
      console.log("[ERROR][updateAxleAlarm] Param : ", point, station, dataId, axle);
      console.log("[ERROR][updateAxleAlarm]", err);
    }
  }

  //update front axle table
  const updateText = (point, procedure, type, dataId) => {
    try {
      if (type === 'id') {
        if (fetchData.data[procedure][0][dataId] !== null) {
          imgRef.current.getElementById(point).children[0].textContent = fetchData.data[procedure][0][dataId];
        }
      }
      else if (type === 'color') {
        if (parseInt(fetchData.data[procedure][0][dataId]) <= 40) {
          imgRef.current.getElementById(point).setAttribute("fill", "red");
        }
        else if (parseInt(fetchData.data[procedure][0][dataId]) > 40) {
          imgRef.current.getElementById(point).setAttribute("fill", "white");
        }
      }
    } catch (err) {
      console.log("[ERROR][updateText] Param : ", point, procedure, type, dataId);
      console.log("[ERROR][updateText]", err);
    }
  }

  const updateStockText = (point, procedure, type, dataId) => {
    try {
      if (dataId === "FC_STOCK_SHIP" || dataId === "RC_STOCK_SHIP") {
        imgRef.current.getElementById(point).children[0].textContent = fetchData.data[procedure][0][dataId] + "/54";
      }
      else if (dataId === "DOCK_A_CNT" || dataId === "DOCK_B_CNT") {
        imgRef.current.getElementById(point).children[0].textContent = "(" + fetchData.data[procedure][0][dataId] + "/36)";
      }
      else {
        imgRef.current.getElementById(point).children[0].textContent = fetchData.data[procedure][0][dataId];
      }
    } catch (err) {
      console.log("[ERROR][updateStockText] Param : ", point, procedure, type, dataId);
      console.log("[ERROR][updateStockText]", err);
    }
  }



  const updateTextColor = (type, procedure, dataId) => {
    try {
      if (parseInt(fetchData.data[procedure][0][dataId]) <= 40) {
        if (type === "f") {
          imgRef.current.getElementById("F_MBUSI").setAttribute("fill", "white");
          imgRef.current.getElementById("frontMBUSI_Id").setAttribute("fill", "white");
          imgRef.current.getElementById("frontMBUSIvar").setAttribute("fill", "white");
        }
        else if (type === "r") {
          imgRef.current.getElementById("R_MBUSI").setAttribute("fill", "white");
          imgRef.current.getElementById("rearMBUSI_Id").setAttribute("fill", "white");
          imgRef.current.getElementById("rearMBUSIvar").setAttribute("fill", "white");
        }
      }
      else {
        if (type === "f") {
          imgRef.current.getElementById("F_MBUSI").setAttribute("fill", "black");
          imgRef.current.getElementById("frontMBUSI_Id").setAttribute("fill", "black");
          imgRef.current.getElementById("frontMBUSIvar").setAttribute("fill", "black");
        }
        else if (type === "r") {
          imgRef.current.getElementById("R_MBUSI").setAttribute("fill", "black");
          imgRef.current.getElementById("rearMBUSI_Id").setAttribute("fill", "black");
          imgRef.current.getElementById("rearMBUSIvar").setAttribute("fill", "black");
        }
      }
    } catch (err) {
      console.log("[ERROR][updateTextColor] Param : ", type, procedure, dataId);
      console.log("[ERROR][updateTextColor]", err);
    }
  }

  const updateStorageColor = () => {
    try {
      if (Math.abs(parseInt(fetchData.data[procedureName[4]][0]["FC_STOCK_SHIP"]) - parseInt(fetchData.data[procedureName[4]][0]["RC_STOCK_SHIP"])) >= 5) {
        imgRef.current.getElementById("FRONT_REC_STOCK").setAttribute("fill", "#F2F200")
        imgRef.current.getElementById("REAR_REC_STOCK").setAttribute("fill", "#F2F200")
      }
      else {
        imgRef.current.getElementById("FRONT_REC_STOCK").setAttribute("fill", "white")
        imgRef.current.getElementById("REAR_REC_STOCK").setAttribute("fill", "white")
      }
    } catch (err) {
      console.log("[ERROR][updateStorageColor]", err);
    }
  }

  //update for elapse
  const updateElapse = (point, procedure, dataId) => {
    try {
      var hour = parseInt(fetchData.data[procedure][0]["ELAPSED_SEQ"].substr(0, 2));
      var min = parseInt(fetchData.data[procedure][0]["ELAPSED_SEQ"].substr(4, 2));
      imgRef.current.getElementById(point).children[0].textContent = fetchData.data[procedure][0][dataId];

      if (hour >= 1 || min >= 20) {
        imgRef.current.getElementById(point).setAttribute("fill", "red");
      }
      else {
        imgRef.current.getElementById(point).setAttribute("fill", "black");

      }
    } catch (err) {
      console.log("[ERROR][updateElapse] Param : ", point, procedure, dataId);
      console.log("[ERROR][updateElapse]", err);
    }
  }

  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", color: "white" }}>
        <Navbar />
      </div>
      <OverviewSvg imgRef={imgRef} height={height} />
    </div>
  );
}

export default Overview;
