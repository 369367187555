import { React, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Navbar from '../component/Nav';
import ShippingSvg from '../component/SVG/ShippingSvg';
import '../font/ModernH-Bold.ttf'
import '../font/ModernH-Light.ttf'
import '../font/ModernH-Medium.ttf'
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { loginRequest } from '../authConfig';

function Shipping() {
  const { instance, accounts } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const navigate = useNavigate();

  useEffect(() => {
    if (!activeAccount) {
      navigate("/login");
    }
  }, [activeAccount]);

  let procedureName = ["up_Monitoring_Shipping_Status1", "up_Monitoring_Shipping_Status2", "up_Monitoring_Shipping_Signal", "up_Monitoring_Shipping_ETD"
    , "up_Monitoring_Shipping_MBUSI", "up_Monitoring_Shipping_Alarm"]

  let trailerType = ["A", "B", "C"]
  const [dock, setDock] = useState(4)
  const [trailerIndex, setTrailerIndex] = useState(0);
  const [height, setHeight] = useState(0);
  const [fetchData, setData] = useState("Not updated");
  const imgRef = useRef();

  useEffect(() => {
    window.addEventListener("load", setHeight(window.innerHeight))
    const debouncedHandleResize = debounce(function handleResize() {
      setHeight(window.innerHeight)
    }, 500)

    getShippingData(true);
    let interval = setInterval(() => {
      getShippingData(false);
    }, 2000)

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
      clearInterval(interval);

    }

  }, [fetchData.time])

  useEffect(() => {

    if (imgRef.current.getElementById("Dock").children[0].textContent === '') {
      imgRef.current.getElementById("Dock").children[0].textContent = 'A'
    }

    if (imgRef.current.getElementById("Dock").children[0].textContent === "A") {
      setDock(4)

    }
    else if (imgRef.current.getElementById("Dock").children[0].textContent === "B") {
      setDock(6)

    }
    else if (imgRef.current.getElementById("Dock").children[0].textContent === "C") {
      setDock(8)

    }
    getShippingData()
  }, [trailerIndex])

  // API call for Data 
  const getShippingData = (isInitialize) => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then(response => {
      const accessToken = response.accessToken;

      axios.get('/api/data/shipping/' + dock, { headers: { 'version': '1.0.3', 'Authorization': `Bearer ${accessToken}` }, withCredentials: true })
        .then((response) => {
          setData(response.data)

          changeText();
        }).catch((err) => {
          if (err?.response?.status === 401) {
            alert("Unauthorized request. Please log in again.");
            const logoutRequest = {
              idTokenHint: accessToken,
              account: instance.getActiveAccount(),
              postLogoutRedirectUri: "/",
              mainWindowRedirectUri: "/",
            };
            instance.logoutRedirect(logoutRequest).catch((e) => {
              console.error(e);
            });
          } else if (err?.response?.status === 403) {
            const resolveWithSomeData = new Promise(resolve => setTimeout(() => {
              resolve();
            }, 3000));

            resolveWithSomeData.then(() => {
              window.customRegistration.unregister().then(() => {
                window.location.reload();
              });
            });
          }
        });
    }).catch(error => {
      console.log("[ERROR] acquireTokenSilent, ", error);
      instance.acquireTokenRedirect(request);
    });
  }

  const changeText = () => {
    updateText("ETD", procedureName[3], "id", "ETD")
    updateText("MBUSI", procedureName[4], "id", "MBUSI")
    updateText("AlarmText", procedureName[5], "id", "Alarm")
    updateColor("DockNo", procedureName[2], "#00FF00")
  }

  //update monitoring  
  const updateText = (point, procedure, type, dataId) => {
    if (type === 'id') {
      if (fetchData.data[procedure][0][dataId] !== null) {
        imgRef.current.getElementById(point).children[0].textContent = fetchData.data[procedure][0][dataId];
      }
    }
  }

  //update color 
  const updateColor = (point, procedure, color) => {
    if (point === "DockNo") {
      if (fetchData.data[procedure][0]["DOCK_STATUS"] === "1") {
        imgRef.current.getElementById(point).setAttribute("fill", color);
      }
      else {
        imgRef.current.getElementById(point).setAttribute("fill", "white");
      }
    }
  }

  const handleElementDblClick = () => {
    imgRef.current.getElementById("Dock").children[0].textContent = trailerType[trailerIndex]

    setTrailerIndex(trailerIndex + 1)
    if (trailerIndex > 1) {
      setTrailerIndex(0);
    }

    setData("Not updated")
  };

  function debounce(fn, ms) {
    let timer
    return () => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", color: "white" }}>
        <Navbar />
      </div>
      <ShippingSvg imgRef={imgRef} height={height} onElementDblClick={handleElementDblClick} />
    </div>
  );
}
export default Shipping;
