import { React, useState, useEffect, useRef } from 'react';
import Navbar from '../component/Nav';
import axios from 'axios';
import LineStatus from '../component/SVG/LineStatusSvg';
import '../font/ModernH-Bold.ttf'
import '../font/ModernH-Light.ttf'
import '../font/ModernH-Medium.ttf'
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { loginRequest } from '../authConfig';

function Status() {
  const { instance, accounts } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const navigate = useNavigate();

  useEffect(() => {
    if (!activeAccount) {
      navigate("/login");
    }
  }, [activeAccount]);

  const [height, setHeight] = useState(0);
  const [fetchData, setData] = useState("Not updated");
  const imgRef = useRef();
  let procedureName = ["up_Monitoring_Line_Station", "up_Monitoring_Line_Device", "up_Monitoring_Line_Alarmcontents"
    , "up_Monitoring_Line_AGV", "up_Monitoring_Line_AGV_Alarm"]

  useEffect(() => {
    window.addEventListener("load", setHeight(window.innerHeight))

    const debouncedHandleResize = debounce(function handleResize() {
      setHeight(window.innerHeight)
    }, 500)

    getStatusData(true);

    let interval = setInterval(() => {
      getStatusData(false);
    }, 5000)

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
      clearInterval(interval);
    }
  }, [fetchData.time])

  // API call for Data 
  const getStatusData = () => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then(response => {
      const accessToken = response.accessToken;

      axios.get('/api/data/linestatus', { headers: { 'version': '1.0.3', 'Authorization': `Bearer ${accessToken}` }, withCredentials: true })
        .then((response) => {
          setData(response.data)
          changeText();
        }).catch((err) => {
          if (err?.response?.status === 401) {
            alert("Unauthorized request. Please log in again.");
            const logoutRequest = {
              idTokenHint: accessToken,
              account: instance.getActiveAccount(),
              postLogoutRedirectUri: "/",
              mainWindowRedirectUri: "/",
            };
            instance.logoutRedirect(logoutRequest).catch((e) => {
              console.error(e);
            });
          } else if (err?.response?.status === 403) {
            const resolveWithSomeData = new Promise(resolve => setTimeout(() => {
              resolve();
            }, 3000));
            resolveWithSomeData.then(() => {
              window.customRegistration.unregister().then(() => {
                window.location.reload();
              });
            });
          }
        });
    }).catch(error => {
      console.log("[ERROR] acquireTokenSilent, ", error);
      instance.acquireTokenRedirect(request);
    });
  }

  const changeText = () => {
    updateStation("stationFront1", procedureName[0], "Front", "01")
    updateStation("stationFront2", procedureName[0], "Front", "02")
    updateStation("stationFront3", procedureName[0], "Front", "03")
    updateStation("stationFront4", procedureName[0], "Front", "04")
    updateStation("stationFront5", procedureName[0], "Front", "05")
    updateStation("stationFront6", procedureName[0], "Front", "06")
    updateStation("stationFront7", procedureName[0], "Front", "07")
    updateStation("stationFront8", procedureName[0], "Front", "08")
    updateStation("stationFront9", procedureName[0], "Front", "09")
    updateStation("stationFront10", procedureName[0], "Front", "10")
    updateStation("stationFront11", procedureName[0], "Front", "11")
    updateStation("stationFront12", procedureName[0], "Front", "12")
    updateStation("stationFront13", procedureName[0], "Front", "13")
    updateStation("stationFront14", procedureName[0], "Front", "14")
    updateStation("stationFront15", procedureName[0], "Front", "15")
    updateStation("stationFront16", procedureName[0], "Front", "16")
    updateStation("stationFront17", procedureName[0], "Front", "17")
    updateStation("stationFront18", procedureName[0], "Front", "18")
    updateStation("stationFront19", procedureName[0], "Front", "19")
    updateStation("stationFront20", procedureName[0], "Front", "20")
    updateStation("stationFront21", procedureName[0], "Front", "21")

    updateStation("stationRear1", procedureName[0], "Rear", "01")
    updateStation("stationRear2", procedureName[0], "Rear", "02")
    updateStation("stationRear3", procedureName[0], "Rear", "03")
    updateStation("stationRear4", procedureName[0], "Rear", "04")
    updateStation("stationRear5", procedureName[0], "Rear", "05")
    updateStation("stationRear6", procedureName[0], "Rear", "06")
    updateStation("stationRear7", procedureName[0], "Rear", "07")
    updateStation("stationRear8", procedureName[0], "Rear", "08")
    updateStation("stationRear9", procedureName[0], "Rear", "09")
    updateStation("stationRear10", procedureName[0], "Rear", "10")
    updateStation("stationRear11", procedureName[0], "Rear", "11")
    updateStation("stationRear12", procedureName[0], "Rear", "12")
    updateStation("stationRear13", procedureName[0], "Rear", "13")
    updateStation("stationRear14", procedureName[0], "Rear", "14")
    updateStation("stationRear15", procedureName[0], "Rear", "15")
    updateStation("stationRear16", procedureName[0], "Rear", "16")
    updateStation("stationRear17", procedureName[0], "Rear", "17")
    updateStation("stationRear18", procedureName[0], "Rear", "18")
    updateStation("stationRear19", procedureName[0], "Rear", "19")
    updateStation("stationRear20", procedureName[0], "Rear", "20")
    updateStation("stationRear21", procedureName[0], "Rear", "21")

    updateDevice("frontToolt", "01", "front")
    updateDevice("frontWheelt", "02", "front")
    updateDevice("frontFlipport", "03", "front")

    updateDevice("rearToolt", "01", "rear")
    updateDevice("rearWheelt", "02", "rear")
    updateDevice("rearFlipport", "03", "rear")

    updateAlarm("alarmText1", "Alarm1", "contents")
    updateAlarm("alarmText2", "Alarm2", "contents")
    updateAlarm("alarmText3", "Alarm2", "contents")
    updateAlarm("alarmAGV", "", "AGV")

    updateAGVbattery("agv1", "AGV_BATTERY", 1)
    updateAGVbattery("agv2", "AGV_BATTERY", 2)
    updateAGVbattery("agv3", "AGV_BATTERY", 3)
    updateAGVbattery("agv4", "AGV_BATTERY", 4)
    updateAGVbattery("agv5", "AGV_BATTERY", 5)
    updateAGVbattery("agv6", "AGV_BATTERY", 6)
    updateAGVbattery("agv7", "AGV_BATTERY", 7)
    updateAGVbattery("agv8", "AGV_BATTERY", 8)
    updateAGVbattery("agv9", "AGV_BATTERY", 9)
    updateAGVbattery("agv10", "AGV_BATTERY", 10)
    updateAGVbattery("agv11", "AGV_BATTERY", 11)
    updateAGVbattery("agv12", "AGV_BATTERY", 12)
    updateAGVbattery("agv13", "AGV_BATTERY", 13)
    updateAGVbattery("agv14", "AGV_BATTERY", 14)
    updateAGVbattery("agv15", "AGV_BATTERY", 15)
    updateAGVbattery("agv16", "AGV_BATTERY", 16)
    updateAGVbattery("agv17", "AGV_BATTERY", 17)
    updateAGVbattery("agv18", "AGV_BATTERY", 18)
    updateAGVbattery("agv19", "AGV_BATTERY", 19)
    updateAGVbattery("agv20", "AGV_BATTERY", 20)
    updateAGVbattery("agv21", "AGV_BATTERY", 21)
    updateAGVbattery("agv22", "AGV_BATTERY", 22)
    updateAGVbattery("agv23", "AGV_BATTERY", 23)
    updateAGVbattery("agv24", "AGV_BATTERY", 24)
    updateAGVbattery("agv25", "AGV_BATTERY", 25)
    updateAGVbattery("agv26", "AGV_BATTERY", 26)
    updateAGVbattery("agv27", "AGV_BATTERY", 27)
    updateAGVbattery("agv28", "AGV_BATTERY", 28)
    updateAGVbattery("agv29", "AGV_BATTERY", 29)
    updateAGVbattery("agv30", "AGV_BATTERY", 30)

    updateAGVtitleColor("a1_t", "AGV_BATTERY", 1, "t1")
    updateAGVtitleColor("a2_t", "AGV_BATTERY", 2, "t2")
    updateAGVtitleColor("a3_t", "AGV_BATTERY", 3, "t3")
    updateAGVtitleColor("a4_t", "AGV_BATTERY", 4, "t4")
    updateAGVtitleColor("a5_t", "AGV_BATTERY", 5, "t5")
    updateAGVtitleColor("a6_t", "AGV_BATTERY", 6, "t6")
    updateAGVtitleColor("a7_t", "AGV_BATTERY", 7, "t7")
    updateAGVtitleColor("a8_t", "AGV_BATTERY", 8, "t8")
    updateAGVtitleColor("a9_t", "AGV_BATTERY", 9, "t9")
    updateAGVtitleColor("a10_t", "AGV_BATTERY", 10, "t10")
    updateAGVtitleColor("a11_t", "AGV_BATTERY", 11, "t11")
    updateAGVtitleColor("a12_t", "AGV_BATTERY", 12, "t12")
    updateAGVtitleColor("a13_t", "AGV_BATTERY", 13, "t13")
    updateAGVtitleColor("a14_t", "AGV_BATTERY", 14, "t14")
    updateAGVtitleColor("a15_t", "AGV_BATTERY", 15, "t15")
    updateAGVtitleColor("a16_t", "AGV_BATTERY", 16, "t16")
    updateAGVtitleColor("a17_t", "AGV_BATTERY", 17, "t17")
    updateAGVtitleColor("a18_t", "AGV_BATTERY", 18, "t18")
    updateAGVtitleColor("a19_t", "AGV_BATTERY", 19, "t19")
    updateAGVtitleColor("a20_t", "AGV_BATTERY", 20, "t20")
    updateAGVtitleColor("a21_t", "AGV_BATTERY", 21, "t21")
    updateAGVtitleColor("a22_t", "AGV_BATTERY", 22, "t22")
    updateAGVtitleColor("a23_t", "AGV_BATTERY", 23, "t23")
    updateAGVtitleColor("a24_t", "AGV_BATTERY", 24, "t24")
    updateAGVtitleColor("a25_t", "AGV_BATTERY", 25, "t25")
    updateAGVtitleColor("a26_t", "AGV_BATTERY", 26, "t26")
    updateAGVtitleColor("a27_t", "AGV_BATTERY", 27, "t37")
    updateAGVtitleColor("a28_t", "AGV_BATTERY", 28, "t28")
    updateAGVtitleColor("a29_t", "AGV_BATTERY", 29, "t29")
    updateAGVtitleColor("a30_t", "AGV_BATTERY", 30, "t30")
  }

  const updateAGVbattery = (point, dataId, num) => {
    try {
      if (parseFloat(fetchData.data["up_Monitoring_Line_AGV"][num - 1][dataId]) >= 100) {
        imgRef.current.getElementById(point).children[0].textContent = parseInt(fetchData.data["up_Monitoring_Line_AGV"][num - 1][dataId]).toFixed()
      } else {
        imgRef.current.getElementById(point).children[0].textContent = parseFloat(fetchData.data["up_Monitoring_Line_AGV"][num - 1][dataId]).toFixed(1)
      }
    } catch {
      imgRef.current.getElementById(point).children[0].textContent = "";
    }
  }

  const updateAGVtitleColor = (point, dataId, num, title) => {
    try {
      imgRef.current.getElementById(title).children[0].textContent = num;
      if (parseFloat(fetchData.data["up_Monitoring_Line_AGV"][num - 1][dataId]) * 10 < 50 && parseFloat(fetchData.data["up_Monitoring_Line_AGV"][num - 1][dataId]) * 10 >= 30) {
        imgRef.current.getElementById(point).setAttribute("fill", "#DAA600");
        imgRef.current.getElementById(title).children[0].textContent = num.toString();
      }
      else if (parseFloat(fetchData.data["up_Monitoring_Line_AGV"][num - 1][dataId]) * 10 < 30) {
        imgRef.current.getElementById(point).setAttribute("fill", "red");
      }
      else {
        imgRef.current.getElementById(point).setAttribute("fill", "black");
      }
    }
    catch {
      imgRef.current.getElementById(title).children[0].textContent = "E";
      imgRef.current.getElementById(point).setAttribute("fill", "red");
    }
  }

  const updateStation = (point, procedure, type, dataId) => {
    if (type === "Front") {
      if (fetchData.data[procedure][0][dataId] === "0") {
        imgRef.current.getElementById(point).children[0].textContent = "-"
      }
      else if (fetchData.data[procedure][0][dataId] === "1") {
        imgRef.current.getElementById(point).children[0].textContent = "🟢"
      }
      else if (fetchData.data[procedure][0][dataId] === "2") {
        imgRef.current.getElementById(point).children[0].textContent = "🟡"
      }
      else {
        imgRef.current.getElementById(point).children[0].textContent = "🔴"
      }
    }
    if (type === "Rear") {
      if (fetchData.data[procedure][1][dataId] === "0") {
        imgRef.current.getElementById(point).children[0].textContent = "-"
      }
      else if (fetchData.data[procedure][1][dataId] === "1") {
        imgRef.current.getElementById(point).children[0].textContent = "🟢"
      }
      else if (fetchData.data[procedure][1][dataId] === "2") {
        imgRef.current.getElementById(point).children[0].textContent = "🟡"
      }
      else {
        imgRef.current.getElementById(point).children[0].textContent = "🔴"
      }
    }
  }

  const updateDevice = (point, dataId, where) => {
    if (where === "front") {
      if (fetchData.data["up_Monitoring_Line_Device"][0][dataId] == "0") {
        imgRef.current.getElementById(point).children[0].textContent = "-"
      }
      else if (fetchData.data["up_Monitoring_Line_Device"][0][dataId] == "1") {
        imgRef.current.getElementById(point).children[0].textContent = "🟢"
      }
      else if (fetchData.data["up_Monitoring_Line_Device"][0][dataId] == "2") {
        imgRef.current.getElementById(point).children[0].textContent = "🟡"
      }
      else {
        imgRef.current.getElementById(point).children[0].textContent = "🔴"
      }

    }
    else if (where === "rear") {
      if (fetchData.data["up_Monitoring_Line_Device"][1][dataId] == "0") {
        imgRef.current.getElementById(point).children[0].textContent = "-"
      }
      else if (fetchData.data["up_Monitoring_Line_Device"][1][dataId] == "1") {
        imgRef.current.getElementById(point).children[0].textContent = "🟢"
      }
      else if (fetchData.data["up_Monitoring_Line_Device"][1][dataId] == "2") {
        imgRef.current.getElementById(point).children[0].textContent = "🟡"
      }
      else {
        imgRef.current.getElementById(point).children[0].textContent = "🔴"
      }
    }
  }

  const updateAlarm = (point, dataId, where) => {
    if (where === "contents") {
      imgRef.current.getElementById(point).children[0].textContent = fetchData.data["up_Monitoring_Line_Alarmcontents"][0][dataId]
    }
    else if (where === "AGV") {
      imgRef.current.getElementById(point).children[0].textContent = fetchData.data["up_Monitoring_Line_AGV_Alarm"][0]["LINE_CODE"] + " " + fetchData.data["up_Monitoring_Line_AGV_Alarm"][0]["AGV_NO"] + " " + fetchData.data["up_Monitoring_Line_AGV_Alarm"][0]["ALARM_CODE"]
    }
  }

  function debounce(fn, ms) {
    let timer
    return () => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", color: "white" }}>
        <Navbar />
      </div>
      <LineStatus imgRef={imgRef} height={height} />
    </div>
  );
}

export default Status;


